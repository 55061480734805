<template>
  <div>
    <navbar title="申请成为渠道"></navbar>
    <div class="box">
      <div v-html="content"></div>
    </div>
    <div class="btn">
      <div @click="refuse">拒绝</div>
      <div @click="applyfor">同意</div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      content: "",
    };
  },
  methods: {
    ...mapActions(["getagreement"]),
    refuse() {
      this.$router.go(-1);
    },
    applyfor() {
      this.$router.push("/applyfor");
    },
  },
  mounted() {
    this.getagreement().then((res) => {
      this.content = res.ResponseBody.content;
    });
  },
};
</script>

<style lang='scss' scoped>
.box {
  padding: 0 0.2rem;
  font-size: 0.32rem;
}
.btn {
  width: 100%;
  position: fixed;
  padding: 0.3rem 0;
  bottom: 0;
  display: flex;
  justify-content: space-around;
  div {
    font-size: 0.32rem;
    line-height: 0.9rem;
    border-radius: 0.45rem;
    color: #fff;
    text-align: center;
    background-color: #649af4;
    width: 3rem;
    height: 0.9rem;
  }
}
</style>